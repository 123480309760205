<template>
  <transition name="modal-v">
    <div class="modal-popup">
      <div class="modal-popup__container">
        <div class="modal-popup__content">
            <div
            class="modal-popup__close-btn "
            @click="closeModal"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.25 5.25L18.75 18.75"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="square"
              />
              <path
                d="M18.75 5.25L5.25 18.75"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="square"
              />
            </svg>
          </div>
          <div class="modal-popup__title">
            Вы сейчас смотрите сайт как незарегистрированный пользователь
          </div>

          <div class="modal-popup__body">
            <div class="body-popup">
              <div class="body-popup__container">
                <div class="body-popup__text text-normal">
                  Зарегистрируйтесь или войдите чтобы получить доступ ко всем
                  материалам сайта
                </div>
              </div>
            </div>
          </div>

          <div class="modal-popup__btn">
            <div
              class="
                modal-popup__btn-undo
                d-md-inline-flex
                button button_empty-pink
              "
              @click="toRegister()"
            >
              Зарегистрироваться
            </div>
            <div
              class="
                modal-popup__btn-accept
                d-md-inline-flex
                button button_pink
              "
               @click="toLogin()"
            >
              Войти
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { bus } from "@/main";
export default {
  name: "ModalPopup",
  props: {
    eventId: Number,
    eventName: {
      type: String,
      default: "event_popup_"
    },
    backUrl: {
      type: Object,
      default: {name: 'MainPage'}
    }
  },
  data: () => ({}),
  methods: {
    closeModal() {
      this.$emit("close", false);
      bus.$emit("scrollLock", false);
    },
     async toRegister() {
      bus.$emit("scrollLock", false);
      localStorage.setItem('source', `${this.eventName}${this.eventId || ''}`)
      localStorage.setItem('backUrl', JSON.stringify(this.backUrl))
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "registration popup interaction", {
          "registration popup interaction": {
            action: "Зарегистрироваться",
             ...this.$root.ymFields
          },
        });
      }
      if (vm.$route.query && vm.$route.query.email) {
        await pe.setPerson({
          email: vm.$route.query?.email,
        });
      }

      pe.track("Azmost", {
        anotherActions: "click",
        landingName: "azmost",
      });
      // const route = this.$router.resolve({
      //   name: "Register",
      //   query: { email: this.$route.query.email || "" },
      // });
      // window.open(route.href, '_blank');
      window.open(this.$root.ssoLinks.registration, "_self");
    },
    async toLogin() {
      bus.$emit("scrollLock", false);
      const vm = this;
      localStorage.setItem('backUrl', JSON.stringify(this.backUrl))
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "registration popup interaction", {
          "registration popup interaction": {
            action: "Войти",
             ...this.$root.ymFields
          },
        });
      }
      // const route = this.$router.resolve({
      //   name: "Login",
      // });
      // window.open(route.href, '_blank');
       window.open(this.$root.ssoLinks.login, "_self");
    },
  },
  mounted() {
    bus.$emit("scrollLock", true);
  },
};
</script>

<style lang="scss" scoped>
.modal-v-enter-active,
.modal-v-leave-active {
  transition: all 0.5s ease;
}
.modal-v-enter,
.modal-v-leave-to {
  opacity: 0;
  transform: translateY(0);
}

.modal-popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(31, 31, 31, 0.6);
  transition: opacity 0.3s ease;
  z-index: 999999999;
   &__close-btn {
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 2;
    span {
      margin-right: 12px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
    @media screen and (max-width: 1220px) {
      position: static;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #000;
      margin-bottom: 12px;
    }
  }
  &__container {
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: auto;
    display: flex;
    @media screen and (max-width: 767px) {
      padding: 68px 16px;
    }
  }
  &__content {
    position: relative;
    width: 100%;
    // max-height: 90vh;
    height: fit-content;
    max-width: 800px;
    margin: auto;
    padding: 48px;
    color: #000;
    background-color: #fff;
    box-sizing: border-box;
    transform: translate(0, 0);
    transition: all 0.3s ease;
    // overflow-y: auto;
    @media screen and (max-width: 1220px) {
      max-width: 592px;
      // margin: 36px auto;
    }
    @media screen and (max-width: 767px) {
      // height: 100%;
      // margin: 0;
      justify-content: flex-start;
      padding: 24px;
    }
  }
  &__title {
    margin-bottom: 24px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #830051;
    @media screen and (max-width: 767px) {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }
  }
  &__body {
    .body-popup {
      &__text {
        margin-bottom: 16px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 22px;
        &.text-bold {
          font-weight: 500;
        }
        &.text-normal {
          font-weight: 400;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &__btn {
    margin-top: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &-undo {
      margin-right: 16px;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-right: 0;
        margin-top: 12px;
      }
    }
    &-accept {
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
}
</style>