import { render, staticRenderFns } from "./koselugo-calculator.vue?vue&type=template&id=598edb19&scoped=true&"
import script from "./koselugo-calculator.vue?vue&type=script&lang=js&"
export * from "./koselugo-calculator.vue?vue&type=script&lang=js&"
import style0 from "./koselugo-calculator.vue?vue&type=style&index=0&id=598edb19&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "598edb19",
  null
  
)

export default component.exports